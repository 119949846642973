import api from '../../api/DarthVaderClient';
import { rscLinkedinIntegrationServiceClient as rscLinkedinIntegrationApi } from '../../api/RscLinkedinIntegrationServiceClient';
import { ModifierType } from '../../constants/Job/JobApplication/TableOrdableConstants';
import CookieManager from '../../managers/CookieManager';

export const jobServiceKeys = {
  all: ['all'],
};

const getFullTextHeaderFromFilter = filter =>
  filter && filter.search && filter.search !== ''
    ? { fulltext: filter.search.trim() }
    : null;

const buildUrlParameters = applicationIds => {
  const params = [];

  if (applicationIds && applicationIds.length > 0) {
    params.push(`?includeApplicationIds=[${applicationIds}]`);
  }
  return params.join('');
};

const getApplicationFilterParams = ({ currentStep }) => {
  const params = [];

  if (currentStep && currentStep.id) {
    params.push({ key: 'stepId', value: currentStep.id });
  }

  if (['reproved', 'give_up'].includes(currentStep.type)) {
    params.push({ key: 'status', value: currentStep.type });
  } else if (currentStep.type === 'all') {
    params.push({ key: 'status', value: 'all' });
  }

  return params.map((param, index) =>
    index === 0
      ? `?${param.key}=${param.value}`
      : `&${param.key}=${param.value}`,
  );
};

const getJobManagementFilterParams = summary => {
  const { filter, order, pagination } = summary;
  const params = [];

  if (order) {
    params.push(`order=${order}`);
  }

  if (filter) {
    if (filter.search && filter.search !== '') {
      params.push(`search=${filter.search}`);
    }

    if (filter.status && filter.status !== '') {
      params.push(`status=${filter.status}`);
    }

    if (filter.publicationType && filter.publicationType !== '') {
      params.push(`publicationType=${filter.publicationType}`);
    }

    if (filter.city && filter.city !== '') {
      params.push(`city=${filter.city}`);
    }

    if (filter.state && filter.state !== '') {
      params.push(`state=${filter.state}`);
    }

    if (filter.involved) {
      params.push('involved');
    }

    if (Array.isArray(filter.recruiterIds) && filter.recruiterIds.length > 0) {
      params.push(`recruiterIds=${filter.recruiterIds}`);
    }

    if (Array.isArray(filter.managerIds) && filter.managerIds.length > 0) {
      params.push(`managerIds=${filter.managerIds}`);
    }

    if (
      Array.isArray(filter.classifierIds) &&
      filter.classifierIds.length > 0
    ) {
      params.push(`classifierIds=${filter.classifierIds}`);
    }

    if (Array.isArray(filter.approverIds) && filter.approverIds.length > 0) {
      params.push(`approverIds=${filter.approverIds}`);
    }

    if (
      Array.isArray(filter.careerPageIds) &&
      filter.careerPageIds.length > 0
    ) {
      params.push(`careerPageIds=${filter.careerPageIds}`);
    }

    if (Array.isArray(filter.creatorIds) && filter.creatorIds.length > 0) {
      params.push(`creatorIds=${filter.creatorIds}`);
    }

    if (Array.isArray(filter.jobTypes) && filter.jobTypes.length > 0) {
      params.push(`jobType=${filter.jobTypes}`);
    }

    if (filter.hiringDateFrom) {
      params.push(`hiringDateFrom=${filter.hiringDateFrom}`);
    }

    if (filter.hiringDateTo) {
      params.push(`hiringDateTo=${filter.hiringDateTo}`);
    }

    if (filter.registerEndDateFrom) {
      params.push(`registerEndDateFrom=${filter.registerEndDateFrom}`);
    }

    if (filter.registerEndDateTo) {
      params.push(`registerEndDateTo=${filter.registerEndDateTo}`);
    }
  }

  if (pagination) {
    if (pagination.page >= 0) {
      params.push(`page=${pagination.page}`);
    }
    if (pagination.perPage) {
      params.push(`perPage=${pagination.perPage}`);
    }
  }

  return params;
};

function getAll(summary) {
  const { maxRetries } = summary;
  const params = getJobManagementFilterParams(summary);
  return api.get(`/jobs?${params.join('&')}`, null, maxRetries);
}

export default {
  getAll,

  async getAllPages({ perPage, filter = {}, order }) {
    const pagination = { perPage, page: 0 };

    const { body } = await getAll({ filter, order, pagination });
    const getNextJobsPromises = [];

    let currentPage = 1;

    const { pageCount, recordsTotal } = body.summary;
    // "Temporary" workaround for enterprise customers that have more than 10k jobs
    const pageLimit = recordsTotal === 10000 ? 20 : pageCount;
    while (currentPage < pageLimit) {
      const nextPagePromise = getAll({
        filter,
        order,
        pagination: { perPage, page: currentPage },
        maxRetries: 3,
      });
      getNextJobsPromises.push(nextPagePromise);
      currentPage += 1;
    }

    const responses = await Promise.all(getNextJobsPromises);
    return responses.reduce(
      (acc, response) => acc.concat(response.body.data),
      body.data,
    );
  },

  get(id) {
    return api.get(`/jobs/${id}`);
  },

  getCities(text) {
    api.get(`/companies/addresses?input=${text}&inputType=(cities)`);
  },

  getSummary(id) {
    return api.get(`/job-management/companies/jobs/${id}/summary`);
  },

  getJobsFilters() {
    return api.get('/jobs/job-management/filters');
  },

  getJobTypes() {
    return api.get(
      '/job-management/companies/jobs/types?order=name&perPage=9999',
    );
  },

  getJobReasonTypes() {
    return api.get(
      '/job-management/public/jobs/reasons?order=name&perPage=9999',
    );
  },

  getJobApproversList(additionalIds) {
    const additionalIdsParameter = additionalIds
      ? `&additionalIds=${additionalIds.join(',')}`
      : '';
    return api.get(
      `/job-management/companies/jobs/approvers?order=name&perPage=9999${additionalIdsParameter}`,
    );
  },

  getJobRecruitersList(jobId) {
    const jobIdParameter = jobId ? `jobId=${jobId}` : '';
    return api.get(
      `/job-management/companies/jobs/recruiters?${jobIdParameter}`,
    );
  },

  getJobManagersList(jobId) {
    const jobIdParameter = jobId ? `jobId=${jobId}` : '';
    return api.get(`/job-management/companies/jobs/managers?${jobIdParameter}`);
  },

  getUsersList(user) {
    return api.get(
      `/job-management/companies/jobs/classifiers?name=${user}&order=name&perPage=100`,
    );
  },

  getClassifiersList(jobId) {
    return api.get(
      `/job-management/companies/jobs/${jobId}/classifiers?order=name&perPage=9999`,
    );
  },

  getJobSteps(jobId, typeFilter) {
    let path = `/job-management/companies/jobs/${jobId}/steps`;
    if (typeFilter) {
      path += `?type=${typeFilter}`;
    }
    return api.get(path);
  },

  getStepFilters(jobId, jobStepId) {
    return api.get(
      `/selection-process/company/job/${jobId}/filter?stepId=${jobStepId}`,
    );
  },

  getJobAddressToFilter(jobId) {
    return api.get(`/companies/jobs/${jobId}/filters/job-address`);
  },

  getFilters(jobId) {
    return api.get(`/selection-process/company/job/${jobId}/filter`);
  },

  getCriterias(jobId) {
    return api.get(`/companies/jobs/${jobId}/criterias?perPage=100`);
  },

  getAvailableFilters() {
    return api.get(
      '/job-management/public/jobs/steps/filters/fields?perPage=100',
    );
  },

  getSchoolingLevels() {
    return api.get(
      '/education-service/public/education/academic-formation?perPage=100',
    );
  },

  getCoursesList() {
    const cookieManager = new CookieManager();
    return api.get(
      '/education-service/public/education/course?perPage=1000',
      null,
      {
        extraHeaders: {
          'accept-language': cookieManager.getLocale(),
        },
      },
    );
  },

  getLanguageList() {
    return api.get(
      '/curriculum-management/public/curriculum/language-name?perPage=1000',
    );
  },

  getLanguageLevelsList() {
    return api.get(
      '/curriculum-management/public/curriculum/language-level?perPage=1000',
    );
  },

  getInstitutionSuggestions(text, lang) {
    return api.get(
      `/education-service/public/education/university?query=${text}&lang=${lang}`,
    );
  },

  patchFilters(jobId, payload, stepId) {
    if (stepId) {
      return api.patch(
        `/selection-process/company/job/${jobId}/filter?stepId=${stepId}`,
        payload,
      );
    }
    return api.patch(`/selection-process/company/job/${jobId}/filter`, payload);
  },

  getApplication(jobId, applicationId, currentStep, filter) {
    const url = `/selection-process/company/job/${jobId}/application/${applicationId}`;
    const params = getApplicationFilterParams({ currentStep });
    return api.get(
      `${url}${params.join('')}`,
      null,
      null,
      getFullTextHeaderFromFilter(filter),
    );
  },

  getProfilePagination({ jobId, applicationId, currentStep, filter }) {
    const url = `/selection-process/company/job/${jobId}/application/${applicationId}/pagination`;
    const params = getApplicationFilterParams({ currentStep });
    return api.get(
      `${url}${params.join('')}`,
      null,
      null,
      getFullTextHeaderFromFilter(filter),
    );
  },

  getProviderTestResult(jobId, applicationId) {
    return api.get(
      `/provider-test/companies/jobs/${jobId}/candidates/${applicationId}/test-provider/result`,
    );
  },

  getProfileTestResult(jobId, applicationId) {
    return api.get(
      `/profile-test/companies/jobs/${jobId}/applications/${applicationId}/profile`,
    );
  },

  saveClickWhatsAppLink({ jobId, applicationId, actionType }) {
    return api.post(
      `/companies/jobs/${jobId}/applications/${applicationId}/history`,
      { actionType },
    );
  },

  getEmailsMessages(jobId, applicationId) {
    return api.get(
      `/communication/companies/jobs/${jobId}/applications/${applicationId}/emails`,
    );
  },

  getApplications({
    jobId,
    currentStep,
    pagination,
    filter,
    tableOrder,
    includeApplicationIds,
    excludeApplicationIds,
  }) {
    let url = [`/selection-process/company/job/${jobId}/application`];

    if (currentStep.type === 'step') {
      url = [
        `/selection-process/company/job/${jobId}/step/${currentStep.id}/application`,
      ];
    }

    url.push(`?page=${pagination.page}&perPage=${pagination.perPage}`);

    if (includeApplicationIds && includeApplicationIds.length > 0) {
      url.push(`&includeApplicationIds=[${includeApplicationIds}]`);
    }

    if (excludeApplicationIds && excludeApplicationIds.length > 0) {
      url.push(`&excludeApplicationIds=[${excludeApplicationIds}]`);
    }

    if (['reproved', 'give_up'].includes(currentStep.type)) {
      url.push(`&status=${currentStep.type}`);
    } else if (currentStep.type === 'all') {
      url.push('&status=all');
    }

    if (tableOrder) {
      const { attribute, modifier } = tableOrder;

      const sortParam =
        modifier === ModifierType.DESC
          ? `&order=!${attribute}`
          : `&order=${attribute}`;

      url.push(sortParam);
    }

    return api.get(
      url.join(''),
      null,
      null,
      getFullTextHeaderFromFilter(filter),
    );
  },

  getDisapprovalReasons() {
    return api.get('/selection-process/company/disqualification-reason');
  },

  sendApplicationsEmail({
    jobId,
    payload,
    searchQuery,
    includeApplicationIds,
  }) {
    const params = buildUrlParameters(includeApplicationIds);
    return api.post(
      `/communication/companies/jobs/${jobId}/applications/emails${params}`,
      payload,
      null,
      getFullTextHeaderFromFilter({ search: searchQuery }),
    );
  },

  postApplicationComment(jobId, applicationId, payload) {
    return api.post(
      `/companies/jobs/${jobId}/applications/${applicationId}/comments`,
      payload,
    );
  },

  postApplicationAttachment(jobId, applicationId, payload) {
    return api.post(
      `/companies/jobs/${jobId}/applications/${applicationId}/attachments`,
      payload,
    );
  },

  postApplicationFavorite(jobId, applicationId, payload) {
    return api.post(
      `/selection-process/company/job/${jobId}/application/${applicationId}/favorite`,
      payload,
    );
  },

  deleteApplicationFavorite(jobId, applicationId, payload) {
    return api.delete(
      `/selection-process/company/job/${jobId}/application/${applicationId}/favorite`,
      payload,
    );
  },

  postApplicationLike(jobId, applicationId, payload) {
    return api.post(
      `/selection-process/company/job/${jobId}/application/${applicationId}/like`,
      payload,
    );
  },

  deleteApplicationLike(jobId, applicationId) {
    return api.delete(
      `/selection-process/company/job/${jobId}/application/${applicationId}/like`,
    );
  },

  postManyTags({ jobId, payload, searchQuery, includeApplicationIds }) {
    const params = buildUrlParameters(includeApplicationIds);
    return api.post(
      `/selection-process/company/job/${jobId}/application/tag${params}`,
      payload,
      null,
      getFullTextHeaderFromFilter({ search: searchQuery }),
    );
  },

  postApplicationTag(jobId, applicationId, payload) {
    return api.post(
      `/companies/jobs/${jobId}/applications/${applicationId}/tags`,
      payload,
    );
  },

  deleteApplicationTag(jobId, applicationId, name) {
    return api.delete(
      `/selection-process/company/job/${jobId}/application/${applicationId}/tag?name=${encodeURIComponent(name)}`,
    );
  },

  postApplicationViewed(jobId, applicationId, payload) {
    return api.post(
      `/selection-process/company/job/${jobId}/application/${applicationId}/view`,
      payload,
    );
  },

  putApplicationCriteria(jobId, applicationId, criteriaId, payload) {
    return api.put(
      `/companies/jobs/${jobId}/applications/${applicationId}/criterias/${criteriaId}`,
      payload,
    );
  },

  deleteApplicationCriteria(jobId, applicationId, criteriaId) {
    return api.delete(
      `/companies/jobs/${jobId}/applications/${applicationId}/criterias/${criteriaId}`,
    );
  },

  changeApplicationStep(jobId, stepId, applicationId) {
    const payload = {
      currentStepId: stepId,
    };

    return api.patch(
      `/companies/jobs/${jobId}/applications/${applicationId}`,
      payload,
    );
  },

  moveApplications({
    jobId,
    applicationIds,
    nextStep,
    currentStep,
    isAllApplicationsSelected,
    filter,
  }) {
    const payload = {
      applicationIds,
      nextStep,
      currentStep,
      isAllApplicationsSelected,
    };
    return api.post(
      `/selection-process/company/job/${jobId}/application/move`,
      payload,
      null,
      getFullTextHeaderFromFilter(filter),
    );
  },

  bulkMove({
    jobId,
    currentStep,
    searchQuery,
    nextStep,
    includeApplicationIds,
  }) {
    const payload = {
      nextStep,
      currentStep,
      applicationIds: [],
      isAllApplicationsSelected: true,
    };

    const params = buildUrlParameters(includeApplicationIds);
    return api.post(
      `/selection-process/company/job/${jobId}/application/move${params}`,
      payload,
      null,
      getFullTextHeaderFromFilter({ search: searchQuery }),
    );
  },

  bulkDisqualify({
    jobId,
    currentStep,
    searchQuery,
    disapprovalReason,
    disapprovalReasonObservation,
    feedbackMessage,
    includeApplicationIds,
  }) {
    const payload = {
      disapprovalReason,
      disapprovalReasonObservation,
      feedbackMessage,
      currentStep,
      applicationIds: [],
      isAllApplicationsSelected: true,
    };

    const params = buildUrlParameters(includeApplicationIds);
    return api.post(
      `/selection-process/company/job/${jobId}/application/disqualify${params}`,
      payload,
      null,
      getFullTextHeaderFromFilter({ search: searchQuery }),
    );
  },

  undoApplicationReprovement(jobId, applicationId, stepId, status) {
    const payload = {
      currentStepId: stepId,
      status,
      disapprovalReason: null,
    };

    return api.patch(
      `/selection-process/company/job/${jobId}/application/${applicationId}`,
      payload,
    );
  },

  disqualifyApplications({
    jobId,
    applicationIds,
    disapprovalReason,
    disapprovalReasonObservation,
    currentStep,
    isAllApplicationsSelected,
    feedbackMessage,
    filter,
  }) {
    const payload = {
      applicationIds,
      disapprovalReason,
      disapprovalReasonObservation,
      currentStep,
      isAllApplicationsSelected,
      feedbackMessage,
    };
    return api.post(
      `/selection-process/company/job/${jobId}/application/disqualify`,
      payload,
      null,
      getFullTextHeaderFromFilter(filter),
    );
  },

  patchApplicationHiringInformation(jobId, applicationId, payload) {
    return api.patch(
      `/companies/jobs/${jobId}/applications/${applicationId}/hiring-information`,
      payload,
    );
  },

  deleteClassifier(jobId, classifierId) {
    return api.delete(
      `/job-management/companies/jobs/${jobId}/classifiers/${classifierId}`,
    );
  },

  postClassifier(jobId, payload) {
    return api.post(
      `/job-management/companies/jobs/${jobId}/classifiers`,
      payload,
    );
  },

  postClassifiersToJobs(payload, summary) {
    if (summary) {
      const params = getJobManagementFilterParams(summary);
      return api.post(
        `/job-management/companies/jobs/classifiers/assign?${params.join('&')}`,
        payload,
      );
    }
    return api.post(
      '/job-management/companies/jobs/classifiers/assign',
      payload,
    );
  },

  patchClassifier(jobId, classifierId, payload) {
    return api.patch(
      `/job-management/companies/jobs/${jobId}/classifiers/${classifierId}`,
      payload,
    );
  },

  post(payload) {
    return api.post('/jobs', payload);
  },

  patch(payload) {
    const { id, ...payloadNoId } = payload;
    return api.patch(`/jobs/${payload.id}`, payloadNoId);
  },

  freezeJob(jobId) {
    return api.post(`/jobs/${jobId}/freeze`);
  },

  getAllSkillsToSuggestions() {
    return api.get('/companies/jobs/skills');
  },

  updateApprovalWorkflow(jobId, payload) {
    return api.patch(
      `/job-management/companies/jobs/${jobId}/approval-workflow`,
      payload,
    );
  },

  deleteJob(id, payload) {
    return api.delete(`/jobs/${id}`, payload);
  },

  duplicateJob(jobId, fromTemplate, asTemplate) {
    return api.post(
      `/job-management/companies/jobs/${jobId}/duplicate?fromTemplate=${!!fromTemplate}&asTemplate=${!!asTemplate}`,
    );
  },

  postStep(payload) {
    return api.post(
      `/job-management/companies/jobs/${payload.jobId}/steps`,
      payload,
    );
  },

  deleteStep(jobId, stepId) {
    return api.delete(
      `/job-management/companies/jobs/${jobId}/steps/${stepId}`,
    );
  },

  getJobStep(jobId, stepId) {
    return api.get(`/job-management/companies/jobs/${jobId}/steps/${stepId}`);
  },

  patchStep(payload) {
    const { id, ...payloadNoId } = payload;
    return api.patch(
      `/job-management/companies/jobs/${payload.jobId}/steps/${payload.stepId}`,
      payloadNoId,
    );
  },

  patchStepReOrder({ jobId, steps }) {
    return api.patch(`/job-management/companies/jobs/${jobId}/steps/reorder`, {
      steps,
    });
  },

  getAdditionalQuestions(jobId, registerStepId, types) {
    if (types && types.length) {
      return api.get(
        `/form-step-activity/company/form/${jobId}/${registerStepId}?types=${types}`,
      );
    }
    return api.get(
      `/form-step-activity/company/form/${jobId}/${registerStepId}`,
    );
  },

  getCustomTests() {
    return api.get('/custom-test/companies');
  },

  postAdditionalQuestion(payload) {
    return api.post(
      `/form-step-activity/company/form/${payload.jobId}`,
      payload,
    );
  },

  deleteAdditionalQuestions(jobId, additionalQuestionId) {
    return api.delete(
      `/form-step-activity/company/form/${jobId}/${additionalQuestionId}`,
    );
  },

  putAdditionalQuestions(jobId, additionalQuestionId, payload) {
    const { id, ...payloadNoId } = payload;
    return api.put(
      `/form-step-activity/company/form/${jobId}/${additionalQuestionId}`,
      payloadNoId,
    );
  },

  getAnalyticsUniqueData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/analyticsuniquedata`);
  },

  getCandidatesByStepData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/candidatesbystep`);
  },

  getAgeGroupData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/agegroup`);
  },

  getGenderData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/gender`);
  },

  getReferredCadidatesData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/referredcandidates`);
  },

  getInternalCandidatesData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/internalcandidates`);
  },

  getDegreeLevelData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/degreelevel`);
  },

  getStateData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/state`);
  },

  getSharingChannelData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/sharingchannel`);
  },

  getProfileTestData(jobId) {
    return api.get(`/analytics/companies/jobs/${jobId}/profiletest`);
  },

  getAvailableJobs(searchQuery, currentJobId) {
    let url = '/selection-process/companies/jobs/assignable-jobs';
    if (searchQuery && currentJobId)
      url = `${url}?searchQuery=${searchQuery}&currentJobId=${currentJobId}`;
    return api.get(url);
  },

  relocateCandidates(payload) {
    return api.post('/selection-process/company/candidate/reallocate', payload);
  },

  relocateAllCandidates({ payload, searchQuery, includeApplicationIds }) {
    const params = buildUrlParameters(includeApplicationIds);
    return api.post(
      `/selection-process/company/job/application/reallocate${params}`,
      payload,
      null,
      getFullTextHeaderFromFilter({ search: searchQuery }),
    );
  },

  pinJob(payload) {
    return api.post('/job-management/companies/jobs/favorite', payload);
  },

  unpinJob(jobId) {
    return api.delete(`/job-management/companies/jobs/${jobId}/favorite`);
  },

  getAllApplicationsWithNoFeedback(jobId) {
    return api.get(`/companies/jobs/${jobId}/applications/no-feedback`);
  },

  sendFeedbackEmails(jobId, payload) {
    return api.post(
      `/communication/companies/jobs/${jobId}/applications/send-feedback-emails`,
      payload,
    );
  },

  sendMessageReadReceipt(threadId, messageId, payload) {
    return api.post(
      `/communication/companies/emails/${threadId}/messages/${messageId}/read`,
      payload,
    );
  },

  patchThreadAllowReply(threadId, allowReply) {
    return api.patch(`/communication/companies/emails/${threadId}`, {
      allowReply,
    });
  },

  getCompanyBranches(search) {
    return api.get(`/company-management/companies/branches?search=${search}`);
  },

  getCompanyBranchesLeaves(search) {
    return api.get(
      `/company-management/companies/branches/leaves?search=${search}`,
    );
  },

  getHiringTypes() {
    return api.get('/selection-process/company/hiring-reason');
  },

  getAvailableJobVacancyCodes(jobId, applicationId) {
    return api.get(
      `/job-management/companies/jobs/${jobId}/available-vacancy-codes?applicationId=${applicationId}`,
    );
  },

  getCompanyCareerPages() {
    return api.get('/career-pages/list');
  },

  getCareerPageSubdomain(jobId) {
    return api.get(
      `/job-publication/companies/jobs/${jobId}/careerPage/subdomain`,
    );
  },

  getMessageTypeList() {
    return api.get('/communication/public/message-types?perPage=1000');
  },

  verifyCandidate(email, jobId) {
    return api.get(
      `/selection-process/company/job/${jobId}/application/email/${email}`,
    );
  },

  addCandidate(jobId, payload) {
    return api.put(
      `/selection-process/company/job/${jobId}/application`,
      payload,
    );
  },

  candidateInsertionRelocateCandidate(jobId, candidateId, partnerName) {
    return api.post(
      `/selection-process/company/job/${jobId}/application/candidate/reallocate-manual-insertion`,
      { candidateId, partnerName },
    );
  },

  sendEmailInvitation(jobId, candidateId, currentStepId) {
    return api.post(
      `/selection-process/company/job/${jobId}/application/invite`,
      { candidateId, currentStepId },
    );
  },

  getPreHireInfo(jobId, applicationId) {
    return api.get(
      `/form-step-activity/company/pre-hire-form/${jobId}/${applicationId}`,
    );
  },

  getRegistrationSchema(jobId) {
    return api.get(
      `/curriculum-step-activity/company/curriculum-schema/${jobId}`,
    );
  },

  saveRegistrationSchema(jobId, payload) {
    return api.put(
      `/curriculum-step-activity/company/curriculum-schema/${jobId}`,
      payload,
    );
  },

  getCandidateTests(jobId, applicationId) {
    return api.get(
      `/provider-test/companies/job/${jobId}/applications/${applicationId}/tests`,
    );
  },

  getTimeline(applicationId) {
    return api.get(
      `/application-timeline/companies/timeline?applicationId=${applicationId}`,
      undefined,
      undefined,
      undefined,
      { headers: true },
    );
  },
  updateUserComment(eventId, payload) {
    return api.patch(
      `/application-timeline/companies/timeline/event/${eventId}`,
      payload,
    );
  },

  getApplicantCount(jobId) {
    return api.get(`/selection-process/company/job/${jobId}/application/count`);
  },

  getCancelReasons() {
    // TODO => Trocar para endpoint da API quando estiver pronto
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            body: [
              'staff_increase_or_substitution_postponed',
              'cancellation_requested_by_manager',
              'internal_transfer',
              'organizational_restructuring',
              'budget_review',
              'other',
            ],
          }),
        1000,
      ),
    );
  },

  cancelJob(jobId, data) {
    return api.post(`/jobs/${jobId}/cancel`, data);
  },

  getCurriculum(candidateId, groupFilters) {
    return api.get(
      `/curriculum-management/company/curriculum/${candidateId}?groupFilter[]=${groupFilters.join(',')}`,
    );
  },

  getCandidateDocuments(applicationId, documentType) {
    return api.get(
      `/curriculum-management/company/application/${applicationId}/document?type=${documentType}`,
    );
  },

  getCandidateDocumentSignedUrl(applicationId, documentId) {
    return api.get(
      `/curriculum-management/company/application/${applicationId}/document/${documentId}`,
    );
  },

  getJobApplicationHistory(
    candidateId,
    options = { pagination: { perPage: 5 } },
  ) {
    const params = getJobManagementFilterParams(options);
    const queryParams = params.length > 1 ? params.join('&') : params[0];
    return api.get(
      `/selection-process/company/candidate/${candidateId}/applications?${queryParams}`,
    );
  },

  getJobAnalysis(payload) {
    return api.post('/job-management/jobs/analysis', payload);
  },

  publishJob(payload) {
    const { id, ...payloadNoId } = payload;

    return api.post(`/jobs/${id}/publish`, payloadNoId);
  },

  saveMedia(payload) {
    const { id: jobId, ...payloadNoJobId } = payload;

    return api.post(
      `/job-publication/companies/jobs/${jobId}/publicity-images`,
      payloadNoJobId,
    );
  },

  getRscLinkedinIntegrationChildAppByCareerPageId(token, careerPageId) {
    return rscLinkedinIntegrationApi.get(
      `/api/v1/child-app/career-pages/${careerPageId}`,
      {},
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getRscLinkedinIntegrationCandidateProfileInMails(
    token,
    candidateId,
    childAppId,
  ) {
    return rscLinkedinIntegrationApi.get(
      `/api/v1/candidate-profiles/${candidateId}/child-apps/${childAppId}/inmail/threads`,
      {},
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  postLinkedRscMember(token, childAppId, candidateId, member) {
    return rscLinkedinIntegrationApi.post(
      '/api/v1/candidate-profiles/link',
      {
        childAppId,
        candidateId,
        member,
      },
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  postUnlinkedRscMember(token, childAppId, candidateId, member) {
    return rscLinkedinIntegrationApi.post(
      '/api/v1/candidate-profiles/unlink',
      {
        childAppId,
        candidateId,
        member,
      },
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getRscLinkedinIntegrationCandidateProfile(token, candidateId, childAppId) {
    return rscLinkedinIntegrationApi.get(
      `/api/v1/candidate-profiles/${candidateId}/child-apps/${childAppId}`,
      {},
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  replaceProfileTestByBehavioralTest(jobId) {
    return api.post(
      `/job-management/companies/jobs/${jobId}/replace-profile-test-by-behavioral-test`,
    );
  },
  getContentMessage(messageId) {
    return api.get(`/communication/message/${messageId}`);
  },

  closeJob(payload) {
    const { jobId, isFeedbackSent } = payload;

    return api.post(`/jobs/${jobId}/close`, {
      isFeedbackSent,
    });
  },

  getJobsInsights() {
    return api.get('/jobs-insights');
  },

  bulkCloseJobs(payload) {
    return api.post('/jobs/bulk-close', payload);
  },
};
