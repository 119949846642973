import {
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@gupy/eco-design-system';
import {
  BorderRadiusLg,
  BorderRadiusSm,
  BorderWidthSm,
  FontSizeXxs,
  PrimaryMain,
  SpacingLg,
  SpacingSm,
  SpacingXs,
  SpacingXxs,
  SpacingXxxs,
  WarningLight,
} from '@gupy/eco-design-tokens';
import styled from 'styled-components';

export const QuickApplyOverview = styled.div`
  width: 80%;
  margin: ${SpacingXxs} auto;
  padding: ${SpacingXxs};
`;

export const QuickApplyOverviewTitle = styled(Typography)`
  padding: 0 0 ${SpacingXxs} 0;
`;

export const QuickApplyOverviewCard = styled(Card)`
  flex: 1;
  border-radius: ${BorderRadiusLg};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const QuickApplyCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: ${SpacingSm};
  margin: ${SpacingXxxs};

  .content-description {
    font-size: ${FontSizeXxs};
    margin-left: ${SpacingLg};
  }
`;

export const QuickApplyCardContentIconTitle = styled(Typography)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  padding-top: ${SpacingXxs};
  gap: ${SpacingXxs};
`;

export const QuickApplyCardContentNoIconTitle = styled(Typography)`
  padding-top: ${SpacingXxs};
  padding-left: ${SpacingLg};
`;

export const InfoBoxes = styled.div`
  display: flex;
  gap: ${SpacingLg};
  justify-content: space-evenly;
`;

export const InfoBox = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 330px;
  border-radius: ${BorderRadiusSm};
  border: ${BorderWidthSm};
  align-items: start;
`;

export const InfoBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${SpacingXxs} ${SpacingXs} ${SpacingXxs} ${SpacingSm};

  ul {
    padding-inline-start: ${SpacingXs};
    margin-top: ${SpacingXxs};
  }

  li {
    padding: 0;
  }

  p {
    font-size: ${FontSizeXxs};
  }

  .benefits p,
  .important p {
    margin-bottom: ${SpacingXs};
  }

  .information-content {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: ${PrimaryMain};
    text-decoration: underline;
    display: block;
    text-align: center;
    margin: 0 auto;
    width: fit-content;
  }
`;

export const QuickApplyImage = styled.img`
  width: 90px;
  height: 64px;
  margin-top: ${SpacingXs};
`;

export const ImportantTypography = styled(Typography)`
  color: ${WarningLight};
`;

export const ActionsComponent = styled(CardActions)`
  display: flex;
  justify-content: flex-end;
`;
