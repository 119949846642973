import { JobRatingCriteriaTypeEnum } from '@gupy/enums';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getSkillsSuccess,
  initJobRatingCriteriasSuccess,
} from '../../../actions/Job/JobRatingCriterias/JobRatingCriteriasAction';
import JobRatingCriteriasActionTypes from '../../../constants/Job/JobRatingCriterias/JobRatingCriteriasActionTypes';
import SkillActionTypes from '../../../constants/Skill/SkillActionTypes';
import JobRatingCriteriasForm from '../../../forms/Job/JobRatingCriterias/JobRatingCriteriasForm';
import JobRatingCriteriasService from '../../../services/Job/JobRatingCriterias/JobRatingCriteriasService';
import JobService from '../../../services/Job/JobService';

function* init(action) {
  const form = new JobRatingCriteriasForm();

  try {
    const criteriasResponse = yield JobRatingCriteriasService.getAll(
      action.jobId,
    );
    const jobResponse = yield JobService.get(action.jobId);

    yield put(
      initJobRatingCriteriasSuccess(
        criteriasResponse.body.data,
        jobResponse.body,
      ),
    );
  } catch (error) {
    if (error.statusCode === 404) {
      window.location = '/companies/jobs/not-found';
    } else {
      yield put({
        type: JobRatingCriteriasActionTypes.INIT_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* post(action) {
  const { jobId, payload } = action;
  const form = new JobRatingCriteriasForm();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobRatingCriteriasActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      yield JobRatingCriteriasService.post(jobId, payload);

      yield put({
        type: JobRatingCriteriasActionTypes.POST_SUCCESS,
      });
      yield init({ jobId });
    } catch (error) {
      yield put({
        type: JobRatingCriteriasActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* patch(action) {
  const { criteriaId, jobId, payload } = action;
  const form = new JobRatingCriteriasForm();

  const newPayload = { ...payload, id: undefined };

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobRatingCriteriasActionTypes.PATCH_FAIL,
      validation,
    });
  } else {
    try {
      yield JobRatingCriteriasService.patch(criteriaId, jobId, newPayload);

      yield put({
        type: JobRatingCriteriasActionTypes.PATCH_SUCCESS,
      });
      yield init({ jobId });
    } catch (error) {
      yield put({
        type: JobRatingCriteriasActionTypes.PATCH_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* del(action) {
  const { jobId, criteriaId } = action;

  try {
    yield JobRatingCriteriasService.delete(criteriaId, jobId);

    yield put({
      type: JobRatingCriteriasActionTypes.DELETE_SUCCESS,
    });
    yield put({
      type: JobRatingCriteriasActionTypes.INIT,
      jobId,
    });
  } catch (error) {
    const form = new JobRatingCriteriasForm();
    yield put({
      type: JobRatingCriteriasActionTypes.DELETE_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

export function* generateSkillsFromGaia(action) {
  try {
    const response = yield JobRatingCriteriasService.generateSkillsFromGaia(
      action.jobId,
    );
    yield put({
      type: JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA_SUCCESS,
      skills: response.body,
    });

    GoogleAnalyticsHelper.sendEvent({
      category: 'SKILLS_EVALUATION_SETUP',
      action: 'skills_gaia_generate_click_success',
    });
    yield init({ jobId: action.jobId });
  } catch (error) {
    GoogleAnalyticsHelper.sendEvent({
      category: 'SKILLS_EVALUATION_SETUP',
      action: 'skills_gaia_generate_click_error',
    });

    yield put({
      type: JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA_FAIL,
      error,
    });
  }
}

function* fetchAllCustomSkills() {
  try {
    const response = yield JobRatingCriteriasService.getAllCustom('');
    return response.body || [];
  } catch (error) {
    yield put({
      type: SkillActionTypes.GET_ALL_FAIL,
    });
    return [];
  }
}

function* searchCustomSkills(searchTerm) {
  try {
    const customSkills =
      yield JobRatingCriteriasService.searchCustom(searchTerm);
    return customSkills.body;
  } catch (error) {
    yield put({
      type: SkillActionTypes.SEARCH_FAIL,
    });
    return null;
  }
}

function* search(action) {
  const form = new JobRatingCriteriasForm();

  try {
    const skills = yield JobRatingCriteriasService.search(action.search);
    const allCustomSkills = yield* fetchAllCustomSkills();
    const customSkills = yield* searchCustomSkills(action.search);
    const hasCustomSkills = allCustomSkills.length > 0;

    const skillTypeMap = skills.body.results.reduce((acc, skill) => {
      acc[skill.externalSkillId] = skill.type;
      return acc;
    }, {});

    const adaptedCustomSkills =
      hasCustomSkills &&
      customSkills.map(customSkill => {
        const { companySkillName, externalSkillId } = customSkill;
        const skillType =
          skillTypeMap[externalSkillId] || JobRatingCriteriaTypeEnum.Skill;

        return {
          ...customSkill,
          name: companySkillName,
          type: skillType,
          isCustom: true,
        };
      });

    const searchResults = hasCustomSkills
      ? adaptedCustomSkills
      : skills.body.results;

    yield put(getSkillsSuccess(searchResults));
  } catch (error) {
    if (error.statusCode === 404) {
      window.location = '/companies/jobs/not-found';
    } else {
      yield put({
        type: JobRatingCriteriasActionTypes.SEARCH_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* JobRatingCriteriasSaga() {
  yield takeLatest(JobRatingCriteriasActionTypes.INIT, init);
  yield takeLatest(JobRatingCriteriasActionTypes.POST, post);
  yield takeLatest(JobRatingCriteriasActionTypes.PATCH, patch);
  yield takeLatest(JobRatingCriteriasActionTypes.DELETE, del);
  yield takeLatest(
    JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA,
    generateSkillsFromGaia,
  );
  yield takeLatest(JobRatingCriteriasActionTypes.SEARCH, search);
}

export default JobRatingCriteriasSaga;
