/* eslint-disable class-methods-use-this */
import { admissionClient } from '../../api/AdmissionClient';
import api from '../../api/DarthVaderClient';

class Service {
  get() {
    return api.get('/authentication/companies/users/current/profile');
  }

  patch(payload) {
    return api.patch(
      '/authentication/companies/users/current/profile',
      payload,
    );
  }

  getCountries() {
    return api.get('/address-service/public/country');
  }
}

export const getFollowerNotificationEvents = async userId =>
  admissionClient.get(`/companies/pre-employee/follower?userId=${userId}`);

export const updateFollowerNotificationEvents = async notificationEvents =>
  admissionClient.patch(
    `/companies/pre-employee/follower/notification-events`,
    { notificationEvents },
  );

export default Service;
