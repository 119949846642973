import { Icon } from '@gupy/design-system';
import { DsScreenSm, DsSpacingXl, IconType } from '@gupy/design-system-v2';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: ${DsSpacingXl};
  align-items: center;

  div {
    width: 500px;

    @media (max-width: ${DsScreenSm}) {
      max-width: 100%;
    }
  }

  svg {
    font-size: 100px;
  }
`;

function ConnectionErrorTryAgain() {
  const handleRefresh = e => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <StyledContainer>
      <div>
        <Icon icon={IconType.ErrorOutline} />
        <h6>
          <FormattedMessage
            id="error-connection"
            defaultMessage="Aconteceu um problema na conexão!"
          />
          <p>
            <a href="#" onClick={handleRefresh}>
              <FormattedMessage
                id="error-connection-try-again"
                defaultMessage="Clique aqui e tente novamente"
              />
            </a>
          </p>
        </h6>
      </div>
    </StyledContainer>
  );
}

export default ConnectionErrorTryAgain;
