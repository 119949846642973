import { datadogRum } from '@datadog/browser-rum';
import { Spinner } from '@gupy/design-system';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ConnectionErrorTryAgain from './ConnectionErrorTryAgain';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const UNLEASH_LOAD_TIMEOUT_IN_MILLI_SECONDS = 10000;

function GlobalErrorHandling({ children }) {
  const [hasUnleashError, setUnleashError] = useState(false);

  const { flagsReady, flagsError } = useFlagsStatus();

  useEffect(() => {
    let timeoutId;

    if (!flagsReady) {
      timeoutId = setTimeout(() => {
        setUnleashError(true);
      }, UNLEASH_LOAD_TIMEOUT_IN_MILLI_SECONDS);
    }

    return () => clearTimeout(timeoutId);
  }, [flagsReady]);

  useEffect(() => {
    if (flagsError) {
      setUnleashError(true);
    }
  }, [flagsError]);

  if (!flagsReady && !hasUnleashError) {
    return (
      <div className="companies-routes-spinner">
        <Spinner size={60} />
      </div>
    );
  }

  if (hasUnleashError) {
    const subdomain = window.location.hostname.split('.')[0];
    datadogRum.addError(new Error('Unleash flags not ready'), { subdomain });

    return <ConnectionErrorTryAgain />;
  }

  return children;
}

GlobalErrorHandling.propTypes = propTypes;

export default GlobalErrorHandling;
