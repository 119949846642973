import PropTypes from 'prop-types';
import React, { Fragment, useRef } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { getMessages } from '../../Messages';
import { response, typeInsights } from './InsightsMessagesEnum';

const propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.func.isRequired,
  setTimeInsights: PropTypes.func,
  handleTypeInsights: PropTypes.func,
};

const InsightsComponent = ({
  isOpen,
  handleInsightsClick,
  intl,
  setTimeInsights,
  handleTypeInsights,
}) => {
  const messagesEndRef = useRef(null);
  const message = getMessages(intl);

  const handleSendQuestionAboutJobPostingTime = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.questionAboutJobPostingTime,
    };
    handleInsightsClick([question]);

    const responseMessage = {
      sender: 'bot-message_response_insights',
      text: response.obterMensagem('responseAboutJobPostingTime'),
    };
    handleInsightsClick([question, responseMessage]);

    setTimeInsights(true);

    const metric = typeInsights.filterTypeInsights('TTP');
    handleTypeInsights(metric);
  };

  const handleSendQuestionFirstApplication = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.questionFirstApplication,
    };
    handleInsightsClick([question]);

    const botMessage = {
      sender: 'bot-message_response_insights',
      text: response.obterMensagem('responseFirstApplication'),
    };
    handleInsightsClick([question, botMessage]);

    setTimeInsights(true);

    const metric = typeInsights.filterTypeInsights('TTA');
    handleTypeInsights(metric);
  };

  const handleSendQuestionHiringTimeVacancy = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.questionHiringTimeVacancy,
    };
    handleInsightsClick([question]);

    const botMessage = {
      sender: 'bot-message_response_insights',
      text: response.obterMensagem('responseHiringTimeVacancy'),
    };
    handleInsightsClick([question, botMessage]);

    setTimeInsights(true);

    const metric = typeInsights.filterTypeInsights('TTH');
    handleTypeInsights(metric);
  };

  const handleSendQuestionClosingTimeVacancy = async () => {
    const question = {
      sender: 'user-message_input',
      text: message.questionClosingTimeVacancy,
    };
    handleInsightsClick([question]);

    const botMessage = {
      sender: 'bot-message_response_insights',
      text: response.obterMensagem('responseClosingTimeVacancy'),
    };
    handleInsightsClick([question, botMessage]);

    setTimeInsights(true);

    const metric = typeInsights.filterTypeInsights('TTF');
    handleTypeInsights(metric);
  };

  return (
    isOpen && (
      <Fragment>
        <div className="message_suggestion">
          <button
            aria-label={message.questionAboutJobPostingTime}
            className="user-message_suggestion"
            onClick={handleSendQuestionAboutJobPostingTime}
          >
            {message.questionAboutJobPostingTime}
          </button>

          <button
            aria-label={message.questionFirstApplication}
            className="user-message_suggestion"
            onClick={handleSendQuestionFirstApplication}
          >
            {message.questionFirstApplication}
          </button>

          <button
            aria-label={message.questionHiringTimeVacancy}
            className="user-message_suggestion"
            onClick={handleSendQuestionHiringTimeVacancy}
          >
            {message.questionHiringTimeVacancy}
          </button>

          <button
            aria-label={message.questionClosingTimeVacancy}
            className="user-message_suggestion"
            onClick={handleSendQuestionClosingTimeVacancy}
          >
            {message.questionClosingTimeVacancy}
          </button>
        </div>
        <div ref={messagesEndRef} />
      </Fragment>
    )
  );
};
InsightsComponent.propTypes = propTypes;
export default injectIntl(InsightsComponent);
