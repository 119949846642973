import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messageCopilot = defineMessages({
  title: {
    id: 'copilot_title_chat',
    defaultMessage: 'Gupy IA',
  },
  labelInitialQuestion: {
    id: 'copilot_gupy_question_initial',
    defaultMessage:
      'Texto enviado pela inteligência artificial: Olá! Sou a Inteligência Artificial da Gupy! Você pode começar uma conversa comigo fazendo uma pergunta sobre as funcionalidades do Gupy Recrutamento & Seleção. Como eu posso te ajudar?',
  },
  userMessageSuggestionPrimary: {
    id: 'copilot_primary_message_suggestion',
    defaultMessage: 'Como divulgar vagas da Gupy no Linkedin?',
  },
  userMessageSuggestionSecondary: {
    id: 'copilot_secondary_message_suggestion',
    defaultMessage: 'Como editar uma vaga na Gupy?',
  },
  footerDescription: {
    id: 'copilot_footer_description',
    defaultMessage:
      'Está é uma funcionalidade BETA e pode apresentar respostas imprecisas por ainda estar em desenvolvimento.',
  },
  send: {
    id: 'copilot_send_message',
    defaultMessage: 'Enviar Pergunta',
  },
  inputPlaceHolder: {
    id: 'copilot_place_holder',
    defaultMessage: 'Escreva a sua pergunta',
  },
  labelOpenCopilot: {
    id: 'copilot_label_open_chat',
    defaultMessage: 'Abrir Copilot FAQ  Inteligencia Artificial',
  },
  labelHeader: {
    id: 'copilot_label_header',
    defaultMessage: 'Gupy Inteligência Artificial em fase beta',
  },
  labelCloseBtn: {
    id: 'btn_close',
    defaultMessage: 'Fechar Copilot',
  },
  labelSuggestionPrimary: {
    id: 'copilot_primary_message_label',
    defaultMessage:
      'Enviar pergunta pré-definida: Como enviar feedback em massa?',
  },
  labelSuggestionSecondary: {
    id: 'copilot_secondary_message_label',
    defaultMessage: 'Enviar pergunta pré-definida: Como encerrar uma vaga?',
  },
  labelFooterDescription: {
    id: 'copilot_footer_description_label',
    defaultMessage:
      'Aviso: Está é uma funcionalidade BETA e pode apresentar respostas imprecisas por ainda estar em desenvolvimento.',
  },
  newRequest: {
    id: 'copilot_new_request',
    defaultMessage: 'Quero outra resposta',
  },
  labelLiked: {
    id: 'copilot_liked_label',
    defaultMessage: 'Gostei da resposta',
  },
  labelDislike: {
    id: 'copilot_dislike_label',
    defaultMessage: 'Não gostei da resposta',
  },
  helloCopilot: {
    id: 'copilot_dialog',
    defaultMessage: 'Está com dúvidas?',
  },
  helloCopilotHelp: {
    id: 'copilot_dialog_help',
    defaultMessage: 'A Gupy IA pode te ajudar!',
  },
  learnMore: {
    id: 'copilot_learn_more',
    defaultMessage: 'Saiba mais em : ',
  },
  userMessageSuggestionInsights: {
    id: 'copilot_insights_message_suggestion',
    defaultMessage: 'Como estão as métricas das vagas?',
  },
  responseWhatInsights: {
    id: 'copilot_insights_question',
    defaultMessage: 'Qual métrica você gostaria de consultar?',
  },
  questionAboutJobPostingTime: {
    id: 'copilot_insights_question_first',
    defaultMessage: 'Tempo de publicação das vagas',
  },
  questionFirstApplication: {
    id: 'copilot_insights_question_second',
    defaultMessage: 'Tempo até a primeira candidatura',
  },
  questionHiringTimeVacancy: {
    id: 'copilot_insights_question_third',
    defaultMessage: 'Tempo de contratação das vagas',
  },
  questionClosingTimeVacancy: {
    id: 'copilot_insights_question_fourth',
    defaultMessage: 'Tempo de fechamento da vaga',
  },
  lastWeekButton: {
    id: 'copilot_insights_btn_week',
    defaultMessage: 'Última semana',
  },
  lastMonthButton: {
    id: 'copilot_insights_btn_month',
    defaultMessage: 'Último mês',
  },
  lastTrimesterButton: {
    id: 'copilot_insights_btn_trimester',
    defaultMessage: 'Último trimestre',
  },
  lastSemesterButton: {
    id: 'copilot_insights_btn_semester',
    defaultMessage: 'Último semestre',
  },
  lastYearButton: {
    id: 'copilot_insights_btn_year_last',
    defaultMessage: 'Último ano',
  },
  suggestionPeriod: {
    id: 'copilot_insights_btn_suggestion_period',
    defaultMessage: 'Sugerir outro período',
  },
  suggestionCompareMetrics: {
    id: 'copilot_insights_compare_insights',
    defaultMessage:
      'Voce gostaria de comparar a métrica com outras empresas que usam a Gupy?',
  },
  compareMetrics: {
    id: 'copilot_insights_compare_yes',
    defaultMessage: 'Sim',
  },
  dontCompareMetrics: {
    id: 'copilot_insights_compare_no',
    defaultMessage: 'Não',
  },
  textNoCompare: {
    id: 'copilot_insights_no_compare_insights',
    defaultMessage:
      'Ok! Caso deseje consultar outras métricas selecione acima.',
  },
});

export const getMessages = intl => formatMessages(intl, messageCopilot);
