import {
  CardContent,
  Button as EcoDsButton,
  Icon,
  Typography,
} from '@gupy/eco-design-system';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ActionsComponent,
  ImportantTypography,
  InfoBox,
  InfoBoxContent,
  InfoBoxes,
  QuickApplyCardContent,
  QuickApplyCardContentIconTitle,
  QuickApplyCardContentNoIconTitle,
  QuickApplyImage,
  QuickApplyOverview,
  QuickApplyOverviewCard,
  QuickApplyOverviewTitle,
} from './JobQuickApplyOverview.styles';

const propTypes = {
  messages: PropTypes.object.isRequired,
  onExitClick: PropTypes.func,
  onCreateJobClick: PropTypes.func,
};

const JobOverviewQuickApply = ({ messages, onExitClick, onCreateJobClick }) => (
  <QuickApplyOverview>
    <QuickApplyOverviewTitle
      variant="heading-large"
      className="quick-apply-job-overview__title"
      data-testid="quick-apply-job-overview__title"
    >
      {messages.quickApplyJobOverviewTitle}
    </QuickApplyOverviewTitle>
    <QuickApplyOverviewCard data-testid="quick-apply-job-overview__card">
      <QuickApplyCardContent>
        <QuickApplyCardContentIconTitle
          variant="title-medium"
          className="icon-title"
        >
          <Icon name="Bolt" color="primary" fontSize="large" />
          {messages.quickApplyJobOverviewCardTitle}
        </QuickApplyCardContentIconTitle>
        <p className="content-description">
          {messages.quickApplyJobOverviewCardDescription}
        </p>
        <InfoBoxes>
          <InfoBox>
            <CardContent>
              <InfoBoxContent>
                <div className="information">
                  <Typography variant="title-small">
                    {messages.quickApplyJobOverviewInformationBoxTitle}
                  </Typography>
                  <div className="information-content">
                    <ul>
                      <li>{messages.quickApplyInformationName}</li>
                      <li>
                        {messages.quickApplyJobOverviewInformationDocument}
                      </li>
                      <li>{messages.quickApplyInformationEmail}</li>
                      <li>{messages.quickApplyInformationPhoneNumber}</li>
                      <li>{messages.quickApplyInformationLinkedin}</li>
                    </ul>
                    <QuickApplyImage
                      className="create-quick-apply__logo"
                      src={`${process.env.REACT_APP_ASSETS_URL}/quick-apply/create-job-overview.svg`}
                      alt="Imagem de inicio do teste customizado"
                    />
                  </div>
                </div>
              </InfoBoxContent>
            </CardContent>
          </InfoBox>
          <InfoBox>
            <CardContent>
              <InfoBoxContent>
                <div className="benefits">
                  <Typography variant="title-small">
                    {messages.quickApplyJobOverviewBenefitsBoxTitle}
                  </Typography>
                  <ul>
                    <li>{messages.quickApplyBenefitsLogin}</li>
                    <li>{messages.quickApplyJobOverviewBenefitsCurriculum}</li>
                    <li>{messages.quickApplyBenefitsFast}</li>
                  </ul>
                </div>
              </InfoBoxContent>
            </CardContent>
          </InfoBox>
          <InfoBox>
            <CardContent>
              <InfoBoxContent>
                <div className="important">
                  <ImportantTypography variant="title-small">
                    {messages.quickApplyJobOverviewImportantBoxTitle}
                  </ImportantTypography>
                  <p>{messages.quickApplyImportantDescription}</p>
                  <a
                    href="https://suporte.gupy.io/s/suporte/article/O-que-sao-vagas-de-Candidatura-Rapida?language=pt_BR"
                    target="_blank"
                  >
                    {messages.quickApplyImportantKnowMore}
                  </a>
                </div>
              </InfoBoxContent>
            </CardContent>
          </InfoBox>
        </InfoBoxes>
        <QuickApplyCardContentNoIconTitle
          variant="title-medium"
          className="no-icon-title"
        >
          {messages.quickApplyJobOverviewCreate}
        </QuickApplyCardContentNoIconTitle>
        <p className="content-description">
          {messages.quickApplyJobOverviewCreateDisclaimer}
        </p>
        <p className="content-description">
          {messages.quickApplyJobOverviewCreateNextSteps1}{' '}
          <strong>{messages.quickApplyJobOverviewCreateNextStepsStrong}</strong>{' '}
          {messages.quickApplyJobOverviewCreateNextSteps2}
        </p>
        <ActionsComponent>
          <EcoDsButton variant="outlined" size="large" onClick={onExitClick}>
            {messages.previousButton}
          </EcoDsButton>
          <EcoDsButton size="large" onClick={onCreateJobClick}>
            {messages.quickApplyJobOverviewCreateButton}
          </EcoDsButton>
        </ActionsComponent>
      </QuickApplyCardContent>
    </QuickApplyOverviewCard>
  </QuickApplyOverview>
);

JobOverviewQuickApply.propTypes = propTypes;
export default JobOverviewQuickApply;
