import { Spinner } from '@gupy/design-system';
import { NetworkAlert } from '@gupy/front-commons';
import AppBar from '@material-ui/core/AppBar';
import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  openConfirmDialog as openConfirmDialogRequest,
  updateGlobalization,
  updateNetworkStatus,
} from '../../../../actions/App/AppActions';
import Copilot from '../../../../components/Copilot/Copilot';
import FooterWrapper from '../../../../components/FooterWrapper/FooterWrapper';
import NavbarWrapper from '../../../../components/NavBarWrapper/NavBarWrapper';
import SidebarWrapper from '../../../../components/SidebarWrapper/SidebarWrapper';
import featuresFlagsEnum from '../../../Authentication/FeaturesFlagsEnum';
import DiversityLegalTermsContainer from '../../../DiversityLegalTerms/Container';
import DefaultAppFooter from '../AppFooter';
import DefaultAppMenu from '../AppMenu/AppMenu';
import { isInsideIframe } from '../helpers/isInsideIframe';
import DefaultSystemAlert from '../SystemAlert';
import {
  Ecosystem,
  MainLayoutSpinner,
  MobileSidebarMenuOverlay,
} from './MainLayout.styles';
import { useLoadEcosystemNav } from './useLoadEcosystemNav';

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  openConfirmDialog: PropTypes.func,
};

const MainLayout = ({
  children,
  Authentication: { currentUser },
  openConfirmDialog,
}) => {
  const shouldRenderHeaderAndFooter = !isInsideIframe();
  const defaultHeaderHeight = '64px';
  const defaultSidebarWidth = '92px';
  const defaultMainMinHeight = '100vmin';
  const shouldDisplayProductAlert = useFlag(featuresFlagsEnum.productAlert);
  const appMenuRef = useRef();
  const isEnabledCopilot = useFlag(featuresFlagsEnum.copilot);
  const shouldDisplayEcosystemNav = useFlag(featuresFlagsEnum.navEcosystem);
  const [menuHeight, updateMenuHeight] = useState(defaultHeaderHeight);
  const [hasAppAlarmDismissed, setHasAppAlarmDismissed] = useState(false);
  const {
    footerData,
    modalData,
    navbarData,
    sidebarData,
    isEcosystemLoading,
    ecosystemLoadError,
  } = useLoadEcosystemNav(currentUser);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);

  useEffect(() => {
    const appMenu = appMenuRef.current;
    const menuObserve = () => {
      const header = appMenu.firstElementChild;
      const headerHeight = header.getBoundingClientRect().height;
      if (headerHeight > 0) updateMenuHeight(`${headerHeight}px`);
    };
    const observer = new MutationObserver(menuObserve);
    const observerConfig = {
      childList: true,
      subtree: true,
    };
    if (appMenu) {
      observer.observe(appMenu, observerConfig);
    }
    return () => observer.disconnect();
  }, [appMenuRef.current]);

  const SystemAlert = () => (
    <DefaultSystemAlert
      currentUser={currentUser}
      onDismiss={setHasAppAlarmDismissed}
      dismiss={hasAppAlarmDismissed}
    />
  );

  const isEcosystemOnDisplay = shouldDisplayEcosystemNav && !ecosystemLoadError;
  const AppMenu = () => {
    if (isEcosystemOnDisplay) {
      return (
        <Fragment>
          <Ecosystem>
            <NetworkAlert onChange={() => {}} />
            <SystemAlert />
            <NavbarWrapper
              id="navbar"
              currentUser={currentUser}
              data={navbarData}
              modalData={modalData}
              setMobileSidebarOpen={setMobileSidebarOpen}
            />
            <SidebarWrapper
              id="sidebar-container"
              currentUser={currentUser}
              data={sidebarData}
              isMobileSidebarOpen={isMobileSidebarOpen}
              setMobileSidebarOpen={setMobileSidebarOpen}
            />
            <MobileSidebarMenuOverlay
              style={
                isMobileSidebarOpen && window.innerWidth < 1024
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            />
          </Ecosystem>
          {isEnabledCopilot ? <Copilot currentUser={currentUser} /> : null}
        </Fragment>
      );
    }

    return (
      shouldRenderHeaderAndFooter && (
        <div ref={appMenuRef} data-testid="default-navigation">
          <AppBar
            elevation={0}
            classes={{
              root: 'mainLayout',
            }}
          >
            <NetworkAlert onChange={() => {}} />
            <SystemAlert />
            <DefaultAppMenu
              currentUser={currentUser}
              openConfirmDialog={openConfirmDialog}
            />
            {isEnabledCopilot ? <Copilot currentUser={currentUser} /> : null}
          </AppBar>
        </div>
      )
    );
  };

  const AppFooter = () => {
    if (shouldDisplayEcosystemNav && footerData)
      return <FooterWrapper data={footerData} />;
    return shouldRenderHeaderAndFooter && <DefaultAppFooter />;
  };

  if (shouldDisplayEcosystemNav && isEcosystemLoading) {
    return (
      <MainLayoutSpinner className="main-layout-spinner">
        <Spinner size={60} />
      </MainLayoutSpinner>
    );
  }

  if (shouldDisplayEcosystemNav && isEcosystemLoading) {
    return (
      <MainLayoutSpinner className="main-layout-spinner">
        <Spinner size={60} />
      </MainLayoutSpinner>
    );
  }

  const mainLayoutStyle = isEcosystemOnDisplay
    ? {
        paddingLeft: window.innerWidth > 1024 && defaultSidebarWidth,
        paddingTop:
          shouldDisplayProductAlert && !hasAppAlarmDismissed ? '102px' : '64px',
        backgroundColor: '#f9f9f9',
        minHeight: defaultMainMinHeight,
      }
    : { paddingTop: menuHeight };

  return (
    <Fragment>
      <DiversityLegalTermsContainer />
      <AppMenu currentUser={currentUser} />
      <main data-testid="main" style={mainLayoutStyle}>
        {children}
      </main>
      <AppFooter />
    </Fragment>
  );
};

MainLayout.propTypes = propTypes;

const mapStateToProps = state => ({
  App: state.reducers.App,
  Authentication: state.reducers.Authentication,
});

export default withRouter(
  connect(mapStateToProps, {
    openConfirmDialog: openConfirmDialogRequest,
    updateGlobalization,
    updateNetworkStatus,
  })(MainLayout),
);
