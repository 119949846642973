import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import CopilotContent from './CopilotContent';
import { getMessages } from './Messages';
import './Copilot.scss';

const propTypes = {
  intl: intlShape.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const HIDE_INFOTIP_COPILOT_KEY = 'hide_infotip_copilot';

const GA_ACTIONS = {
  openCopilot: 'copilot-click-to-open',
  closeCopilot: 'copilot-click-to-close',
};

const sendMetricsToGA = action => {
  GoogleAnalyticsHelper.sendEvent({
    category: `COPILOT_CLICK_${action === GA_ACTIONS.openCopilot ? 'TO_OPEN' : 'TO_CLOSE'}`,
    action,
  });
};

const Copilot = ({ intl, currentUser }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const message = getMessages(intl);

  const Dialog = isOpen =>
    isOpen && (
      <div className={`infotip-copilot ${isDialogOpen ? 'open' : 'closing'}`}>
        <p>{message.helloCopilot}</p>
        <p>{message.helloCopilotHelp}</p>
      </div>
    );

  useEffect(() => {
    const storedValue = localStorage.getItem(HIDE_INFOTIP_COPILOT_KEY);
    if (storedValue === 'null') {
      const openTimer = setTimeout(() => {
        setIsDialogOpen(true);
        const closeTimer = setTimeout(() => {
          setIsDialogOpen(false);
        }, 10000);
        return () => clearTimeout(closeTimer);
      }, 5000);
      return () => clearTimeout(openTimer);
    }
    return () => {};
  }, []);

  const handleCloseCopilot = () => {
    const copilotElement = document.querySelector('.copilot');
    if (!copilotElement) return;

    const animationEndHandler = () => {
      setIsChatOpen(false);
      copilotElement.classList.remove('closing');
      copilotElement.removeEventListener('animationend', animationEndHandler);
    };

    copilotElement.addEventListener('animationend', animationEndHandler, {
      once: true,
    });

    copilotElement.classList.add('closing');
    setIsDialogOpen(false);
    sendMetricsToGA(GA_ACTIONS.closeCopilot);

    localStorage.setItem(HIDE_INFOTIP_COPILOT_KEY, true);
  };

  const handleOpenCopilot = () => {
    setIsDialogOpen(false);
    setIsChatOpen(true);
    sendMetricsToGA(GA_ACTIONS.openCopilot);

    localStorage.setItem(HIDE_INFOTIP_COPILOT_KEY, true);
  };

  return (
    <Fragment>
      {!isChatOpen ? (
        <Fragment>
          <Dialog isOpen={isDialogOpen} />
          <div className="button-open-copilot">
            <button
              aria-label={message.labelOpenCopilot}
              className="icon-button"
              onClick={handleOpenCopilot}
            >
              <img
                alt=""
                src={`${process.env.REACT_APP_ASSETS_URL}/copilot/icon.svg`}
              />
            </button>
          </div>
        </Fragment>
      ) : (
        <CopilotContent
          isOpen={() => setIsChatOpen(true)}
          onClose={handleCloseCopilot}
          currentUser={currentUser}
        />
      )}
    </Fragment>
  );
};

Copilot.propTypes = propTypes;
export default injectIntl(Copilot);
