import { datadogRum } from '@datadog/browser-rum';
import { Spinner } from '@gupy/design-system';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import React, { useState } from 'react';
import FeaturesFlagsEnum from './Authentication/FeaturesFlagsEnum';
import ConnectionErrorTryAgain from './Error/ConnectionErrorTryAgain';
import { keycloak, keycloakInitOptions } from './keycloak';

const IDPProvider = ({ children }) => {
  const { flagsReady } = useFlagsStatus();
  const isLegacySignOn = useFlag(FeaturesFlagsEnum.legacySignOn);
  const [hasKeycloakError, setHasKeycloakError] = useState(false);
  const [keycloakEventAndError, setKeycloakEventAndError] = useState(null);

  const isSSOEnabled = !isLegacySignOn;

  const onKeycloakEvent = (event, error) => {
    if (error) {
      setKeycloakEventAndError({ event, error });
      setHasKeycloakError(true);
    }
  };

  if (hasKeycloakError) {
    const subdomain = window.location.hostname.split('.')[0];
    datadogRum.addError(new Error(`Keycloak has error.`), {
      subdomain,
      error: keycloakEventAndError,
    });

    return <ConnectionErrorTryAgain />;
  }

  if (!flagsReady) {
    return (
      <div className="companies-routes-spinner">
        <Spinner size={60} />
      </div>
    );
  }

  if (flagsReady && isSSOEnabled) {
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={keycloakInitOptions}
        onEvent={onKeycloakEvent}
      >
        {children}
      </ReactKeycloakProvider>
    );
  }

  return children;
};

export default IDPProvider;
