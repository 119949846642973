import { WorkplaceTypeEnum } from '@gupy/enums';
import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  detailTitle: {
    id: 'job-detail-title',
    defaultMessage: 'Detalhe os dados de sua vaga',
  },
  howDoWantPublish: {
    id: 'job-how-do-want-publish',
    defaultMessage: 'Como você quer divulgar a vaga?',
  },
  howDoWantPublishDescription: {
    id: 'job-how-do-want-publish-description',
    defaultMessage:
      'Divulgue sua vaga em diferentes canais de comunicação e comece a receber as aplicações dos candidatos.',
  },
  howRateCandidates: {
    id: 'job-how-to-rate-the-candidates',
    defaultMessage: 'Como avaliar os candidatos?',
  },
  howRateCandidatesDescription: {
    id: 'job-how-to-rate-the-candidates-description',
    defaultMessage:
      'Defina as etapas de seu processo de recrutamento e os critérios de avaliação dos candidatos.',
  },
  jobManagement: {
    id: 'job-job-management',
    defaultMessage: 'Gestão da vaga',
  },
  menuClose: {
    id: 'job-menu-close',
    defaultMessage: 'Sair',
  },
  menuCloseAndSave: {
    id: 'job-menu-close-save',
    defaultMessage: 'Salvar e sair',
  },
  menuHomeLink: {
    id: 'job-menu-home-link',
    defaultMessage: 'Acessar home',
  },
  menuNewJob: {
    id: 'job-menu-new-job',
    defaultMessage: 'Nova vaga',
  },
  menuTitle: {
    id: 'job-menu-title',
    defaultMessage: 'Requisição de vaga',
  },
  quickApplyJobMenuTitle: {
    id: 'job-menu-title-quick-apply',
    defaultMessage: 'Candidatura Rápida',
  },
  newJob: {
    id: 'job-new-job',
    defaultMessage: 'Nova vaga',
  },
  newJobs: {
    id: 'job-new-jobs',
    defaultMessage: 'Novas vagas',
  },
  newJobsDescription: {
    id: 'job-new-jobs-description',
    defaultMessage:
      'As informações referentes à sua vaga e a forma como você irá conduzir o processo de recrutamento e seleção podem causar um grande impacto em seu futuro colaborador. Por isso, o passo a passo ao lado pode te ajudar neste desafio!',
  },
  startNow: {
    id: 'job-start-now',
    defaultMessage: 'Começar agora',
  },
  whatIsTheJob: {
    id: 'job-what-is-the-job',
    defaultMessage: 'Qual é a vaga?',
  },
  whatIsTheJobDescription: {
    id: 'job-what-is-the-job-description',
    defaultMessage:
      'Descreva a sua vaga, deixando claro aos seus candidatos quais são as qualificações e requisitos esperados.',
  },
  whatIsTheJobCreate: {
    id: 'job-what-is-the-job-create',
    defaultMessage:
      'Descreva a vaga de forma clara, informando aos candidatos quais são as qualificações e requisitos esperados. Nesta etapa, pode criar uma nova vaga ou escolher um dos modelos de vaga disponíveis.',
  },
  whoApproveDescription: {
    id: 'job-who-approve-description',
    defaultMessage:
      'Determine os membros do time de sua empresa que se encarregará de avaliar e aprovar os candidatos.',
  },
  whoShouldAnalyzeCandidates: {
    id: 'job-who-should-analyze-candidates',
    defaultMessage: 'Quem deve avaliar os candidatos?',
  },
  accessNavMenu: {
    id: 'job-access-nav-menu',
    defaultMessage: 'Acessar menu de navegação',
  },
  jobTemplates: {
    id: 'job_template',
    defaultMessage: 'Templates de vaga',
  },
  noJobTemplateFound: {
    id: 'no_job_template_found',
    defaultMessage: 'Nenhum template de vaga encontrado',
  },
  duplicatedStepError: {
    id: 'job-step-online-duplicated-step-error',
    defaultMessage:
      'Houve um erro nas etapas da vaga. Acesse a aba “Definição de Etapas” e tente novamente ou entre em contato com o time de suporte da Gupy.',
  },
  settingsRegisterTabList: {
    id: 'job-step-settings-register-tab-list',
    defaultMessage:
      'Seção usada para configurar a etapa de cadastro do candidato',
  },
  previousButton: {
    id: 'job-previous-button',
    defaultMessage: 'Voltar',
  },
  giveTitleForYourJob: {
    id: 'give_title_for_your_job',
    defaultMessage: 'Dê um nome para sua vaga',
  },
  giveTitleForYourJobInfotip: {
    id: 'give_title_for_your_job_infotip',
    defaultMessage:
      'O campo "Nome da vaga", é a informação que ficará visível para as pessoas candidatas.',
  },
  knowMoreJobDetails: {
    id: 'knowMoreJobDetails',
    defaultMessage: 'Saiba mais sobre criação de vaga',
  },
  whatIsTheRoleOfJob: {
    id: 'what_is_the_role_of_job',
    defaultMessage: 'Qual o cargo da vaga?',
  },
  whatIsTheDepartmentOfJob: {
    id: 'what_is_the_department_of_job',
    defaultMessage: 'Qual a área de trabalho?',
  },
  whatIsTheTypeOfJob: {
    id: 'what_is_the_type_of_job',
    defaultMessage: 'Qual é o tipo da vaga?',
  },
  jobIsForHandicapped: {
    id: 'this_job_is_for_handicapped_not_required',
    defaultMessage: 'Vaga também para PcD',
  },
  continue: {
    id: 'continue',
    defaultMessage: 'Continuar',
  },
  updateContinue: {
    id: 'update_continue',
    defaultMessage: 'Atualizar e continuar',
  },
  confidentialQuestion: {
    id: 'confidential_question',
    defaultMessage: 'Deseja que essa vaga seja confidencial?',
  },
  checkboxConfidentialLabel: {
    id: 'checkbox_confidential_label',
    defaultMessage: 'Sim, essa vaga é confidencial',
  },
  imagesUploadFieldError: {
    id: 'job-descriptions-form-images-upload-field-error',
    defaultMessage:
      'Algumas imagens não foram processadas corretamente. Recomendamos a revisão do conteúdo anexado.',
  },
  imagesUploadSuccess: {
    id: 'job-descriptions-form-images-upload-success',
    defaultMessage: 'Imagens carregadas com sucesso!',
  },
  informCompanyBranch: {
    id: 'inform_a_company_branch_for_this_job',
    defaultMessage: 'Informe a estrutura organizacional para esta vaga',
  },
  selectJobSubsidiary: {
    id: 'select_job_subsidiary',
    defaultMessage: 'Informe a filial, se houver',
  },
  newBadge: {
    id: 'new_badge',
    defaultMessage: 'Novo',
  },
  newBadgeDescription: {
    id: 'new_badge_description',
    defaultMessage:
      'Esta nova opção de publicação de vaga garante que apenas colaboradores autorizados tenham acesso, via área logada de candidatos, às vagas de recrutamento interno da empresa.',
  },
  externalDescription: {
    id: 'external_description',
    defaultMessage:
      'Sua vaga será publicada em portais e redes sociais selecionados e também poderá ser vista por qualquer pessoa que acessar a sua página de carreiras.',
  },
  externalVacancyCareerPage: {
    id: 'external_vacancy_career_page',
    defaultMessage: 'Página de carreira',
  },
  externalVacancy: {
    id: 'external_vacancy',
    defaultMessage: 'Vaga externa',
  },
  internalRecruitmentDescription: {
    id: 'internal_recruitment_description',
    defaultMessage:
      'A vaga será publicada na página de vagas internas. Apenas os colaboradores cadastrados poderão visualizar e se candidatar nessa vaga.',
  },
  internalRecruitment: {
    id: 'internal_recruitment',
    defaultMessage: 'Recrutamento interno',
  },
  confidentialDescription: {
    id: 'confidential_description',
    defaultMessage:
      'A vaga não será publicada em sua página de carreiras externa, portais ou redes sociais. Apenas pessoas com o link da vaga poderão acessá-la. Você poderá usá-la para testar a plataforma.',
  },
  unlistedVacancy: {
    id: 'unlisted_vacancy',
    defaultMessage: 'Não-listada ou Vaga teste',
  },
  confidentialVacancy: {
    id: 'confidential_vacancy',
    defaultMessage: 'Vaga interna ou Vaga teste',
  },
  publicationTypeHeading: {
    id: 'publication_type_heading',
    defaultMessage: 'Tipo de publicação',
  },
  publicationTypesInformation: {
    id: 'publication_types_info',
    defaultMessage: 'Informações sobre tipos de publicação',
  },
  publicationCareerPage: {
    id: 'publication_career_page',
    defaultMessage: 'Página de carreira',
  },
  publicationCareerPagePlaceholder: {
    id: 'publication_career_page_placeholder',
    defaultMessage: 'Digite ou selecione uma página de carreira',
  },
  publicType: {
    id: 'public_type',
    defaultMessage: 'Pública',
  },
  internalType: {
    id: 'internal_type',
    defaultMessage: 'Interna',
  },
  confidentialType: {
    id: 'confidential_type',
    defaultMessage: 'Confidencial',
  },
  unlistedType: {
    id: 'unlisted_type',
    defaultMessage: 'Não listada',
  },
  noInternalCareerPages: {
    id: 'no_internal_career_pages',
    defaultMessage:
      'Você ainda não possui uma página de carreira interna cadastrada. Deseja criar uma?',
  },
  createInternalCareerPageButton: {
    id: 'create_internal_career_page_button',
    defaultMessage: 'Criar página de carreira interna',
  },
  invalidHiringDate: {
    id: 'invalid_hiring_date',
    defaultMessage:
      'A data de contratação não pode ser anterior a presente data',
  },
  tip: {
    id: 'tip',
    defaultMessage: 'Dica',
  },
  draft: {
    id: 'job_status_draft',
    defaultMessage: 'Rascunho',
  },
  waiting_approval: {
    id: 'job_status_waiting_approval',
    defaultMessage: 'Em aprovação',
  },
  approved: {
    id: 'job_status_approved',
    defaultMessage: 'Aprovada',
  },
  disapproved: {
    id: 'job_status_disapproved',
    defaultMessage: 'Reprovada',
  },
  published: {
    id: 'job_status_published',
    defaultMessage: 'Publicada',
  },
  frozen: {
    id: 'job_status_frozen',
    defaultMessage: 'Congelada',
  },
  closed: {
    id: 'job_status_closed',
    defaultMessage: 'Encerrada',
  },
  canceled: {
    id: 'job-status-canceled',
    defaultMessage: 'Cancelada',
  },
  template: {
    id: 'job_status_template',
    defaultMessage: 'Template',
  },
  notAllowedToPublish: {
    id: 'not_allowed_to_publish',
    defaultMessage: 'Sem permissão para publicar',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  close: {
    id: 'close',
    defaultMessage: 'Fechar',
  },
  please: {
    id: 'please',
    defaultMessage: 'Por favor',
  },
  forbiddenError: {
    id: 'job_publish_forbidden_error',
    defaultMessage: 'Você não tem permissões para publicar a vaga.',
  },
  cannotPublishJobWithPastExpiresAt: {
    id: 'cannot_publish_job_with_past_expires_at',
    defaultMessage:
      'A data de contratação não pode ser anterior a presente data. Revise a informação e',
  },
  provideRequiredFields: {
    id: 'provide-required-fields',
    defaultMessage: 'preencha os dados adicionais obrigatórios',
  },
  provideAnExpectedHiringDate: {
    id: 'provide_an_expected_hiring_date',
    defaultMessage: 'informe uma data prevista para contratação.',
  },
  provideAnExpectedDeadlineDate: {
    id: 'provide_an_expected_deadline_date',
    defaultMessage:
      'informe uma data limite de inscrição maior ou igual a data de hoje.',
  },
  provideANewExpectedHiringDate: {
    id: 'provide_a_new_expected_hiring_date',
    defaultMessage: 'informe uma nova data prevista para contratação.',
  },
  chooseJobPublicationMedia: {
    id: 'choose_job_publication_media',
    defaultMessage: 'Escolha as mídias que aparecerão na divulgação da vaga',
  },
  jobPublicationTypeModalTitle: {
    id: 'job_publication_type_modal_title',
    defaultMessage: 'Saiba mais sobre os tipos de publicação',
  },
  jobPublicationTypeModalContent: {
    id: 'job_publication_type_modal_content',
    defaultMessage:
      'Para que você faça a escolha mais adequada, entenda melhor o que significa cada tipo de publicação. Ao selecionar uma delas, você poderá escolher uma página de carreira associada a ela.',
  },
  jobPublicationTypeModalPublic: {
    id: 'job_publication_type_modal_public',
    defaultMessage: 'Pública',
  },
  jobPublicationTypeModalPublicContent: {
    id: 'job_publication_type_modal_public_content',
    defaultMessage:
      'sua vaga será publicada em uma página de carreira pública e será divulgada em portais e redes sociais selecionados. Poderá ser vista por qualquer pessoa que acessar a sua página de carreiras.',
  },
  jobPublicationTypeModalInternal: {
    id: 'job_publication_type_modal_internal',
    defaultMessage: 'Interna',
  },
  jobPublicationTypeModalInternalContent: {
    id: 'job_publication_type_modal_internal_content',
    defaultMessage:
      'a vaga será publicada em uma página de carreira interna. Apenas os colaboradores cadastrados poderão visualizar e se candidatar nessa vaga.',
  },
  jobPublicationTypeModalConfidential: {
    id: 'job_publication_type_modal_confidential',
    defaultMessage: 'Confidencial',
  },
  jobPublicationTypeModalConfidentialContent: {
    id: 'job_publication_type_modal_confidential_content',
    defaultMessage:
      'a vaga será publicada na página de carreira confidencial, sua empresa não será identificada e ela não será divulgada em portais ou redes sociais.',
  },
  jobPublicationTypeModalUnlisted: {
    id: 'job_publication_type_modal_unlisted',
    defaultMessage: 'Não listada',
  },
  jobPublicationTypeModalUnlistedContent: {
    id: 'job_publication_type_modal_unlisted_content',
    defaultMessage:
      'a vaga não será publicada em sua página de carreiras externa, portais ou redes sociais. Apenas pessoas com o link da vaga poderão acessá-la.',
  },
  jobPublicationTypeModalFooterButton: {
    id: 'job_publication_type_modal_footer_button',
    defaultMessage: 'Ok, entendi',
  },
  changeCareerPageModalTitle: {
    id: 'change_career_page_modal_title',
    defaultMessage: 'Tem certeza que deseja alterar a página de carreira?',
  },
  changeCareerPageModalDescription: {
    id: 'change_career_page_modal_description',
    defaultMessage:
      'As pessoas colaboradoras que se inscreveram serão informadas que a vaga foi alterada para outra página de carreira e continuarão participando do processo.',
  },
  changeCareerPageModalConfirm: {
    id: 'change_career_page_modal_confirm',
    defaultMessage: 'Alterar página de carreira',
  },
  duplicateJobButton: {
    id: 'duplicate_job_button',
    defaultMessage: 'duplicar esta vaga',
  },
  externalPublicationTypeBottomTipEditing1: {
    id: 'publication_type_bottomTip_editing_external_1',
    defaultMessage:
      'Depois que uma vaga pública é publicada, não é possível alterar a página de carreiras ou o tipo de publicação. Você pode',
  },
  externalPublicationTypeBottomTipEditing2: {
    id: 'publication_type_bottomTip_editing_external_2',
    defaultMessage: 'e fazer os ajustes necessários.',
  },
  internalPublicationTypeBottomTipEditing1: {
    id: 'publication_type_bottomTip_editing_internal_1',
    defaultMessage:
      'Ao alterar o tipo de publicação para pública, as pessoas colaboradoras que se inscreveram serão informadas que a vaga foi alterada para outra página de carreira e continuarão participando do processo. Você também pode',
  },
  internalPublicationTypeBottomTipEditing2: {
    id: 'publication_type_bottomTip_editing_internal_2',
    defaultMessage:
      'para alterar o tipo de publicação, página de carreira e fazer outros ajustes necessários.',
  },
  confidentialPublicationTypeBottomTipEditing1: {
    id: 'publication_type_bottomTip_editing_confidential_1',
    defaultMessage:
      'Depois que uma vaga confidencial é publicada, não é possível alterar a página de carreiras ou o tipo de publicação. Você pode',
  },
  confidentialPublicationTypeBottomTipEditing2: {
    id: 'publication_type_bottomTip_editing_confidential_2',
    defaultMessage: 'e fazer os ajustes necessários.',
  },
  unlistedPublicationTypeBottomTipEditing1: {
    id: 'publication_type_bottomTip_editing_unlisted_1',
    defaultMessage:
      'Depois que uma vaga não listada é publicada, só é possível alterar o tipo de publicação de acordo com a página de carreira escolhida previamente. Por exemplo, se você publicou em uma página de carreira interna, você poderá escolher o tipo de publicação interna. Caso deseje alterar outras informações, você pode',
  },
  unlistedPublicationTypeBottomTipEditing2: {
    id: 'publication_type_bottomTip_editing_unlisted_2',
    defaultMessage: 'para fazer os ajustes necessários.',
  },
  jobSuccessfullyDuplicated: {
    id: 'job_successfully_duplicated',
    defaultMessage: 'Vaga duplicada com sucesso!',
  },
  jobFailedDuplicated: {
    id: 'job_failed_duplicated',
    defaultMessage: 'Ocorreu um erro na tentativa de duplicar esta vaga.',
  },
  jobTemplateSaved: {
    id: 'job_template_saved',
    defaultMessage: 'Template salvo com sucesso!',
  },
  jobDescription: {
    id: 'job_description_label',
    defaultMessage: 'Descrição da Vaga',
  },
  skillsDefinition: {
    id: 'skills_definition',
    defaultMessage: 'Definição de habilidades',
  },
  culturalValueDefinition: {
    id: 'cultural-value-definition-text',
    defaultMessage: 'Definição de valores culturais',
  },
  skills: {
    id: 'skills',
    defaultMessage: 'Habilidades',
  },
  taxonomySkillsDescription: {
    id: 'taxonomy_skills_description',
    defaultMessage:
      'As habilidades nessa página foram extraídas diretamente da descrição da vaga e baseadas em vagas anteriormente criadas pela empresa. Este processo foi realizado pela Gupy IA, a inteligência artificial da Gupy, especialista em recrutamento e seleção.',
  },
  mandatoryInfo: {
    id: 'mandatory_info',
    defaultMessage:
      'Ao marcar "Obrigatório", a habilidade se torna um requisito essencial para a pessoa candidata à vaga. Caso não esteja marcado, a habilidade será considerada como um diferencial, indicando que o candidato se destacará se possuir tal competência.',
  },
  habilitiesColumnDescription: {
    id: 'habilities_column_description',
    defaultMessage: 'Habilidades',
  },
  mandatoryColumnDescription: {
    id: 'mandatory_column_description',
    defaultMessage: 'Obrigatório',
  },
  habilityEmptyList: {
    id: 'hability_empty_list',
    defaultMessage: 'Nenhuma habilidade adicionada',
  },
  typeAndSelectAnOption: {
    id: 'type_and_select_an_option',
    defaultMessage: 'Digite e selecione uma opção',
  },
  isGaiaSuggestion: {
    id: 'is_gaia_suggestion',
    defaultMessage: 'Sugerido pela Gupy IA',
  },
  add: {
    id: 'add',
    defaultMessage: 'Adicionar',
  },
  culturalValues: {
    id: 'cultural_values',
    defaultMessage: 'Valores Culturais',
  },
  culturalValuesInfo: {
    id: 'cultural_values_info',
    defaultMessage:
      'Os valores culturais não são exibidos para a pessoa candidata',
  },
  addCulturalValueName: {
    id: 'add_cultural_values_name',
    defaultMessage: 'Digite um valor cultural',
  },
  remainingSkills: {
    id: 'remaining_skills',
    defaultMessage: 'habilidades restantes',
  },
  remainingCulturalValues: {
    id: 'remaining_cultural_values',
    defaultMessage: 'valores culturais restantes',
  },
  culturalValuesEmptyList: {
    id: 'cultural_values_empty_list',
    defaultMessage: 'Nenhum valor cultural adicionado',
  },
  culturalValuesColumnDescription: {
    id: 'cultural_values_column_description',
    defaultMessage: 'Valores culturais',
  },
  limitReachedOfSkills: {
    id: 'limit_reached_of_skills',
    defaultMessage: 'Você atingiu o limite de habilidades cadastradas.',
  },
  limitReachedOfCulturalValues: {
    id: 'limit_reached_of_cultural_values',
    defaultMessage: 'Você atingiu o limite de valores culturais cadastrados.',
  },
  generateSkills: {
    id: 'generate_skills',
    defaultMessage: 'Gerar Habilidades',
  },
  new: {
    id: 'new',
    defaultMessage: 'Novo',
  },
  beta: {
    id: 'beta',
    defaultMessage: 'Beta',
  },
  previewAsRecruiter: {
    id: 'view_as_recruiter',
    defaultMessage: 'Visualizar como Avaliação',
  },
  previewAsCandidate: {
    id: 'view_as_candidate',
    defaultMessage: 'Visualizar como Candidato',
  },
  taxonomyPreviewModalCandidateTitle: {
    id: 'taxonomy_preview_modal_candidate_title',
    defaultMessage: 'Exemplo de visualização da página de pessoa candidata',
  },
  taxonomyPreviewModalRecruiterTitle: {
    id: 'taxonomy_preview_modal_recruiter_title',
    defaultMessage: 'Exemplo de visualização da página de pessoa recrutadora',
  },
  jobCannotBePublished: {
    id: 'job_cannot_be_published',
    defaultMessage: 'A vaga não pode ser publicada',
  },
  maxAdditionalQuestionsRegisterLink: {
    id: 'max_additional_questions_register_link',
    defaultMessage: 'Cadastro',
  },
  maxAdditionalQuestions: {
    id: 'max_additional_questions',
    defaultMessage: 'não é possível adicionar mais de 30 perguntas por vaga.',
  },
  workMode: {
    id: 'work-mode',
    defaultMessage: 'Modo de trabalho',
  },
  requiredField: {
    id: 'required_field',
    defaultMessage: 'Campo obrigatório',
  },
  [WorkplaceTypeEnum.hybrid]: {
    id: 'workplace_type_hybrid',
    defaultMessage: 'hybrid',
  },
  [WorkplaceTypeEnum.onSite]: {
    id: 'workplace_type_on_site',
    defaultMessage: 'on-site',
  },
  [WorkplaceTypeEnum.remote]: {
    id: 'workplace_type_remote',
    defaultMessage: 'remote',
  },
  skillsSuggestionsByGaia: {
    id: 'skills_suggestions_by_gaia',
    defaultMessage: 'Sugestão de habilidades gerada pela Gupy IA',
  },
  taxonomySkillsGenerateSkillsErrorToastHeader: {
    id: 'taxonomy_skills_generate_skills_error_toast_header',
    defaultMessage: 'Um erro inesperado aconteceu. Tente novamente.',
  },
  taxonomySkillsGenerateSkillsErrorModalTitle: {
    id: 'taxonomy_skills_generate_skills_error_modal_title',
    defaultMessage: 'A Gupy IA não conseguiu gerar habilidades para essa vaga.',
  },
  taxonomySkillsGenerateSkillsErrorModalSubTitle: {
    id: 'taxonomy_skills_generate_skills_error_modal_sub_title',
    defaultMessage:
      'Adicione mais detalhes ao campo <strong>“Descreva os requisitos e qualificações, falando sobre ferramentas, habilidades, cursos e certificações.”</strong> na aba Descrição da vaga para que as habilidades possam ser geradas.',
  },
  taxonomySkillsGenerateSkillsErrorModalReviewField: {
    id: 'taxonomy_skills_generate_skills_error_modal_review_field',
    defaultMessage: 'Revisar campo',
  },
  invalidDate: {
    id: 'invalid-date',
    defaultMessage: 'Data inválida',
  },
  pastDateNotAllowed: {
    id: 'past-date-not-allowed',
    defaultMessage: 'Atenção: Não é possível inserir datas passadas.',
  },
  loadingLabel: {
    id: 'loading-label',
    defaultMessage: 'Carregando...',
  },
  skillsAddedSuccessfully: {
    id: 'skills-added-successfully',
    defaultMessage: 'Habilidade(s) adicionada(s) com sucesso',
  },
  gupyIaRecommendation: {
    id: 'gupy-ia-recommendation',
    defaultMessage: 'Recomendação Gupy IA',
  },
  talentPoolJobNameWarningTip: {
    id: 'talent-pool-job-name-warning-tip',
    defaultMessage:
      'Atenção: o nome contém termos que podem indicar que a vaga é do tipo "Banco de talentos", por isso fizemos a mudança do tipo de vaga automaticamente. Revise as informações para prosseguir.',
  },
  warning: {
    id: 'job-details-form-warning',
    defaultMessage: 'Atenção:',
  },
  talentPoolJobNameWarningTipQuickApply: {
    id: 'talent-pool-job-name-quick-apply-warning-tip',
    defaultMessage:
      ' vagas com candidatura rápida não podem ser usadas como banco de talentos. O título da vaga não deve conter termos que indiquem esse tipo de uso. Por favor, revise o texto para continuar.',
  },
  quickApplyJobOverviewTitle: {
    id: 'job-overview-quick-apply-title',
    defaultMessage: 'Candidatura Rápida',
  },
  quickApplyJobOverviewCardTitle: {
    id: 'job-overview-quick-apply-card-title',
    defaultMessage: 'O que é uma vaga de candidatura rápida?',
  },
  quickApplyJobOverviewCardDescription: {
    id: 'job-overview-quick-apply-card-description',
    defaultMessage:
      'A candidatura rápida facilita o processo de inscrição para atrair mais pessoas candidatas.',
  },
  quickApplyJobOverviewInformationBoxTitle: {
    id: 'job-overview-quick-apply-information-box-title',
    defaultMessage: 'Quais informações são solicitadas à pessoa candidata?',
  },
  quickApplyInformationName: {
    id: 'job-overview-quick-apply-information-name',
    defaultMessage: 'Nome completo',
  },
  quickApplyJobOverviewInformationDocument: {
    id: 'job-overview-quick-apply-information-document',
    defaultMessage: 'CPF',
  },
  quickApplyInformationEmail: {
    id: 'job-overview-quick-apply-information-email',
    defaultMessage: 'E-mail',
  },
  quickApplyInformationPhoneNumber: {
    id: 'job-overview-quick-apply-information-phone-number',
    defaultMessage: 'Número de celular',
  },
  quickApplyInformationLinkedin: {
    id: 'job-overview-quick-apply-information-linkedin',
    defaultMessage: 'LinkedIn (opcional)',
  },
  quickApplyJobOverviewBenefitsBoxTitle: {
    id: 'job-overview-quick-apply-benefits-box-title',
    defaultMessage: 'Benefícios da candidatura rápida',
  },
  quickApplyBenefitsLogin: {
    id: 'job-overview-quick-apply-benefits-login',
    defaultMessage: 'Não exige login e senha para a pessoa candidata',
  },
  quickApplyJobOverviewBenefitsCurriculum: {
    id: 'job-overview-quick-apply-benefits-curriculum',
    defaultMessage: 'Não exige preenchimento do CV',
  },
  quickApplyBenefitsFast: {
    id: 'job-overview-quick-apply-benefits-fast',
    defaultMessage: 'Tem um tempo médio de inscrição de 2 minutos',
  },
  quickApplyJobOverviewImportantBoxTitle: {
    id: 'job-overview-quick-apply-important-box-title',
    defaultMessage: 'Importante:',
  },
  quickApplyImportantDescription: {
    id: 'job-overview-quick-apply-important-description',
    defaultMessage:
      'A ordenação da IA, recursos online (testes e vídeo) e perguntas adicionais não estão disponíveis nesse tipo de vaga.',
  },
  quickApplyImportantKnowMore: {
    id: 'job-overview-quick-apply-important-know-more',
    defaultMessage: 'Saiba mais',
  },
  quickApplyJobOverviewCreate: {
    id: 'job-overview-quick-apply-create',
    defaultMessage: 'Criar vaga de candidatura rápida',
  },
  quickApplyJobOverviewCreateDisclaimer: {
    id: 'job-overview-quick-apply-create-disclamer',
    defaultMessage:
      'Uma vez criada a vaga do tipo candidatura rápida, não é possível alterar para o modelo de vaga padrão.',
  },
  quickApplyJobOverviewCreateNextSteps1: {
    id: 'job-overview-quick-apply-create-next-steps-1',
    defaultMessage: 'Nas próximas etapas você poderá',
  },
  quickApplyJobOverviewCreateNextStepsStrong: {
    id: 'job-overview-quick-apply-create-next-steps-strong',
    defaultMessage: 'configurar detalhes, divulgação e avaliação',
  },
  quickApplyJobOverviewCreateNextSteps2: {
    id: 'job-overview-quick-apply-create-next-steps-2',
    defaultMessage: 'da vaga.',
  },
  quickApplyJobOverviewCreateButton: {
    id: 'job-overview-quick-apply-create-button',
    defaultMessage: 'Criar vaga de candidatura rápida',
  },
});

export const getJobMessages = intl => formatMessages(intl, messages);
