import { ApiClient } from '@gupy/api-client';

const admissionClient = new ApiClient({
  signInUrl:
    process.env.REACT_APP_SIGNIN_PATH ||
    'http://gupy.test.gupy.io:3000/companies/signin',
  tokenHeaderParam: 'company_key_auth',
  tokenCookieName: process.env.REACT_APP_AUTH_TOKEN_NAME || 'company_token',
  urlBase: process.env.REACT_APP_ADMISSION_API_URL || '',
});

export { admissionClient };
